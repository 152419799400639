import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProjects } from './api';
import Carousel from './Carousel';
import ArtGallery from './ArtGallery';
import './CategoryPage.css';

const CategoryPage = () => {
  const { category } = useParams();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProjects()
      .then(response => {
        const filteredProjects = response.data.filter(p => p.category === category);
        setProjects(filteredProjects);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [category]);

  if (loading) {
    return <p>Loading projects...</p>;
  }

  return (
    <div className={`category-page ${category === 'Art' ? 'full-page' : ''}`}>
      {category === 'Art' ? (
        <ArtGallery />
      ) : (
        <>
          <h2>{category}</h2>
          {projects.length > 0 ? (
            projects.map(project => (
              <div key={project._id} className="project">
                <div className="carousel-container">
                  <Carousel 
                    images={project.images}
                  />
                </div>
                <div className="project-description">
                  <h3>{project.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: project.description }} />
                </div>
              </div>
            ))
          ) : (
            <p>No projects found for this category.</p>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryPage;
