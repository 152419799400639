import React, { useEffect, useState } from 'react';
import './ArtGallery.css';

const ArtGallery = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Exemple de données pour les tests
    const testProjects = [
      {
        _id: '1',
        title: 'Projet Art 1',
        category: 'Art',
        images: [
          '/client/build/images/art/20170305_153638.jpg',
          '/client/build/images/art/draws.png',
          '/client/build/images/art/z.jpg',
          '/client/build/images/art/kimono+txt.png',
          '/client/build/images/art/stormprim.png',
          '/client/build/images/art/roots.jpg'
        ]
      }
    ];
    setProjects(testProjects);
  }, []);

  return (
    <div className="art-gallery">
      {projects.map((project) => (
        project.images.map((image, index) => (
          <div
            key={`${project._id}-${index}`}
            className={`art-image-container item-${index + 1}`}
          >
            <img src={image} alt={project.title} className="art-image" />
          </div>
        ))
      ))}
    </div>
  );
};

export default ArtGallery;