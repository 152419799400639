import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getProjects } from './api';
import './Portfolio.css';
import './Carousel.css';

const Portfolio = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects()
      .then(response => setProjects(response.data))
      .catch(error => console.error('Error fetching projects:', error));
  }, []);

  const categories = [...new Set(projects.map(project => project.category))];

  const orderedCategories = ['Architecture', 'Design', 'Art'].filter(category =>
    categories.includes(category)
  );

  return (
    <div className="portfolio">
      {orderedCategories.map(category => (
        <div key={category} className="category">
          <Link to={`/category/${category}`}>
            <img
              src={
                category === 'Architecture'
                  ? '/client/build/images/Architecture/jardinpartagéssite/12.png'
                  : category === 'Art'
                  ? '/client/build/images/art/lady.png'
                  : category === 'Design'
                  ? '/client/build/images/Design/fsp/12.png'
                  : `https://via.placeholder.com/300?text=${category}`
              }
              alt={category}
            />
            <div className="category-details">
              <h3>{category}</h3>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
