import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Portfolio from './Portfolio';
import CategoryPage from './CategoryPage';
import './App.css';

const logoPath = '/client/build/images/Logo.png'; // Chemin absolu vers l'image

function App() {
  return (
    <Router>
      <div className="App">
        <header className="page-header">
          <h1>
            <Link to="/">
              <img src={logoPath} alt="BENSETTI Design Logo" style={{ height: '50px' }} />
            </Link>
          </h1>
          <p><span className="highlight-comment">//</span> Designer, Gérant de l’entreprise BENSETTI Design</p>
          <p><span className="highlight-comment">//</span> Enseignant-chercheur en Design Industriel et prospectif</p>
        </header>
        <Routes>
          <Route path="/" element={<Portfolio />} />
          <Route path="/category/:category" element={<CategoryPage />} />
        </Routes>
        <footer>
          <p><span className="highlight-comment">//</span> Contact :</p>
          <p>BENSETTI Yasser</p>
          <p>bensetti.design [at] gmail [dot] com</p>
          <p>
            <a href="https://www.bensettidesign.fr" target="_blank" rel="noopener noreferrer">
              www.bensettidesign.fr
            </a>
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
